import React, { Component } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";

import Label from "components/Elements/Label";
import Button from "components/Elements/Button";

import AuthUserContext from "../components/Session/AuthUserContext";
import withAuthorization from "../components/Session/withAuthorization";

import { getMarketingCopy } from "api/db";
import { auth } from "api/firebase";

import { SmallPill } from "components/Post/styles";

import { Row, Col } from "react-grid-system";

import { Project, ArticleWrapper } from "components/Post/styles";
import HeaderLivePreview from "components/Post/HeaderLivePreview";

import hljs from "highlight.js";
import Markdown from "react-remarkable-with-plugins";
import Spinner from "react-spinkit";
import { populateMarketingCopy } from "api/db";

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

const Container = styled.div`
  font-size: 20px;
  padding: 32px;
`;

const InputContainer = styled.div`
  text-align: center;
  .input-submit {
    margin-bottom: 1.5em;
  }
`;

const PreviewContainer = styled.div`
  max-width: 50em;
  margin: 0 auto;
  height: 600px;
  overflow: auto;
`;

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

class CopyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: "",
      group: "",
      title: "",
      heading: "",
      description: "",
      label: "",
      createPage: false,
      loading: true,
      copyUpdated: false,
    };
  }

  componentDidMount() {
    let path = this.props.location.pathname.slice(6).split("/");
    let group = path[0];
    let slug = path[1];

    this.setState({ slug: slug, group: group });
    getMarketingCopy(group, slug).then(
      function(snapshot) {
        let data = snapshot.val();
        console.log(data);
        this.setState({
          title: data.title,
          heading: data.heading,
          label: data.label,
          description: data.description,
          loading: false,
          createPage: data.createPage
        });
      }.bind(this)
    );
  }

  onUpdateCopy = (event) => {
    event.preventDefault();
    populateMarketingCopy(
      this.state.group,
      this.state.slug,
      this.state.heading,
      this.state.label,
      this.state.description,
      this.state.title,
      this.state.createPage
    );
    this.setState({
      copyUpdated: true,
    });
  };

  onCreatePageClicked = event => {
    event.preventDefault();
    this.setState({
      createPage: !this.state.createPage
    });
  };

  render() {
    if (
      auth.currentUser.uid == "qODlzGtsA0QMv5hxNqC0qy6lIRy1" ||
      auth.currentUser.uid == "7bqTTkoDvua4M3lJDpLD2Ga9Lmp2" ||
      auth.currentUser.uid == "yN5k2h91h3WFNudUWfBT3suVVep2"
    ) {
      return (
        <div>
          <Helmet title="Edit Project Details | Enlight" />
          <AuthUserContext.Consumer>
            {(authUser) => (
              <div>
                <Container>
                  <Label>
                    {" "}
                    <a href="/admin/marketing"> &larr; Back</a>{" "}
                  </Label>
                  <br />
                  {this.state.loading ? (
                    <SpinnerContainer>
                      <Spinner name="ball-scale-multiple" color="#438cee" />
                    </SpinnerContainer>
                  ) : (
                    <div>
                      <Row>
                        <Col>
                          <InputContainer>
                            <div className="input-submit">
                              <label>Title</label>
                              <input
                                className="large input-center"
                                onChange={(event) => {
                                  this.setState(
                                    updateByPropertyName(
                                      "title",
                                      event.target.value
                                    )
                                  );
                                }}
                                type="text"
                                placeholder="title"
                                value={this.state.title}
                                readOnly
                              />
                            </div>
                            <div className="input-submit">
                              <label>Slug</label>
                              <input
                                className="large input-center"
                                onChange={(event) => {
                                  this.setState(
                                    updateByPropertyName(
                                      "slug",
                                      event.target.value
                                    )
                                  );
                                }}
                                type="text"
                                placeholder="slug"
                                value={this.state.slug}
                                readOnly
                              />
                            </div>
                            <div className="input-submit">
                              <label>Heading</label>
                              <input
                                className="large input-center"
                                onChange={(event) => {
                                  this.setState(
                                    updateByPropertyName(
                                      "heading",
                                      event.target.value
                                    )
                                  );
                                }}
                                type="text"
                                placeholder="heading"
                                value={this.state.heading}
                              />
                            </div>
                            <div className="input-submit">
                              <label>Label</label>
                              <input
                                className="large input-center"
                                onChange={(event) => {
                                  this.setState(
                                    updateByPropertyName(
                                      "label",
                                      event.target.value
                                    )
                                  );
                                }}
                                type="text"
                                placeholder="label"
                                value={this.state.label}
                              />
                            </div>
                            <div onClick={this.onCreatePageClicked}>
                              {this.state.createPage ? (
                                <SmallPill c="#7ef29d">Creating Page</SmallPill>
                              ) : (
                                <SmallPill c="#f7b3b7">Not Creating Page</SmallPill>
                              )}
                            </div>
                            <div className="input-submit" style={{"margin-top": "40px"}}>
                              <label>Description</label>
                              <textarea
                                className="submission"
                                onChange={(event) => {
                                  this.setState(
                                    updateByPropertyName(
                                      "description",
                                      event.target.value
                                    )
                                  );
                                }}
                                type="text"
                              >
                                {this.state.description}
                              </textarea>
                            </div>
                          </InputContainer>
                        </Col>
                      </Row>

                      <br />
                      {this.state.copyUpdated ? (
                        <Button>Done &#10004;</Button>
                      ) : (
                        <Button onClick={this.onUpdateCopy}>
                          Publish Changes
                        </Button>
                      )}
                    </div>
                  )}
                </Container>
              </div>
            )}
          </AuthUserContext.Consumer>
        </div>
      );
    } else {
      return "404";
    }
  }
}
const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(CopyPage);
